.proficiency-header {
  color: #edaa45 !important;
}

.custom-color {
  color: #326da1;
}

code {
  font-weight: bolder;
  font-size: 1.2rem;
  padding: 0.2rem;
}

.resume-image {
  transition: transform 0.3s ease;
}

.resume-image:hover {
  transform: scale(1.1);
}

.resume-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #edaa45;
  color: white;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ui.medium.image:hover .resume-overlay {
  opacity: 1;
}

#code-container {
  min-width: 50%;
}
