.project-image {
  opacity: 1;
}
.float-left {
  float: left;
  margin-right: 20px;
}

.float-right {
  float: right;
  margin-left: 20px;
}

.project-float {
  position: relative;
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #f2e9e0;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: #f2e9e0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-overlay:hover {
  opacity: 1;
}

.project-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.project-links a {
  display: flex;
  align-items: center;
  color: #f2e9e0;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 22px;
}

.project-links a:last-child {
  margin-bottom: 0;
}
