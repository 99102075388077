/* styles.css */

/* Header styles */
#my-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
}

/* Larger screens */
@media (min-width: 768px) {
  #my-header {
    max-width: 80%; /* Adjust the percentage as needed */
  }
}

/* Even larger screens */
@media (min-width: 1024px) {
  #my-header {
    max-width: 75%; /* Adjust the percentage as needed */
  }
}

/* Even larger screens */
@media (min-width: 1524px) {
  #my-header {
    max-width: 50%; /* Adjust the percentage as needed */
  }
}

/* Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #141213;
  color: #f2e9e0;
  padding: 20px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links a {
  color: #f2e9e0;
  font-size: 24px;
  margin: 0 10px;
}

.footer-links a:hover {
  color: lightgray;
}
